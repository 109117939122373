#root,
.main__wrapper {
  min-height: 550px;
}

.ant-layout {
  background-color: #fafafa !important;
}

.ant-layout-sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border: none !important;
  }
}

.ant-layout-content {
  padding: 0 50px 100px 50px;
  margin-left: 80px;
}

.card__main {
  margin: 20px 0;
  box-shadow: 0 2.08325px 1.76623px rgb(0 113 242 / 2%),
    0 5.75991px 4.88341px rgb(0 113 242 / 3%),
    0 13.8677px 11.7574px rgb(0 113 242 / 3%),
    16px 24px 32px rgb(0 113 242 / 3%),
    inset -6px -6px 12px rgb(237 243 252 / 40%);
}

.ant-table {
  tr {
    & > th,
    & > td {
      font-size: 12px;
    }
  }
}

.ant-tag {
  border-radius: 10px;
  text-align: center;
}

@border-radius-base: 4px;