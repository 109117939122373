.tasks__table {
  .ant-tag.tag {
    width: 80px;
  }
  .ant-table-row {
    cursor: pointer;
  }
  .ant-table-row:hover {
    .ant-table-cell {
      background-color: #e6f7ff;
    }
  }
}

.group__tasks {
  width: 250px;
  float: left;
  .group__name {
    width: 120px;
    float: left;
  }
}

@border-radius-base: 4px;