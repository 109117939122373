.selected__items {
  .ant-list-item {
    padding: 0px !important;
  }
  .item__margin {
    margin-top: 15px;
  }
}

.input__number {
  width: 100% !important;
  .ant-input-number-handler-wrap {
    display: none;
  }
}

@border-radius-base: 4px;