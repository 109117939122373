/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.date__container {
  text-align: center;
}
.date__container .date__today {
  border: 1px solid #1890ff;
}
.meetings__description {
  padding: 0px 10px;
  margin: 10px 0px;
}
.meeting__card {
  background-color: #fafafa;
  padding: 14px !important;
  border-radius: 10px;
  margin-bottom: 10px;
}
.meeting__card * {
  font-size: 12px !important;
}
