.inline__form {
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
}

.margin__bottom {
  margin-bottom: 12px;
}

.ant-table-cell {
  .ant-btn.ant-btn-link {
    font-size: 12px;
  }
}

@border-radius-base: 4px;