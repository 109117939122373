.float__label {
  position: relative;
  .label {
    font-size: 12px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 12px;
    top: 12px;
    transition: 0.2s ease all;
  }
  .label__float {
    top: -6px;
    font-size: 10px;
    background-color: white;
    padding: 0 6px;
  }
}

@border-radius-base: 4px;