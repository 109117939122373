.bottom__panel,
.top__panel {
  box-shadow: rgba(149, 157, 165, 0.2) 50px 0px 10px;
  position: fixed;
  background-color: white;
  width: calc(100% - 80px);
  padding: 12px 36px;
  left: 80px;
  z-index: 1000;
}

.bottom__panel {
  bottom: 0px;
  .right__panel {
    float: right;
  }
  .left__panel {
    float: left;
  }
  button {
    margin-right: 8px;
  }
}

@border-radius-base: 4px;